import { useEffect, useState } from "react";
import { fetchData } from "../utils/api";
import DoughnutChart from "../charts/DoughnutChart";

export default function StatusCard() {
    const [beans, setBeans] = useState([]);

    useEffect(() => {
      const getBeans = async () => {
        const beanData = await fetchData();
        const fillLevel = beanData?.fill_level;
        const timeStamp = beanData?.timestamp;
        
        const dateTime = new Date(timeStamp).toLocaleDateString("de", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });

        setBeans({ fillLevel, dateTime });
      };

      getBeans();
    }, []);

    return (
        <div className="rounded-2xl border-gray-900 border px-8 pb-8">
            <h2 className="mt-10 text-2xl font-semibold leading-6">
                Status
            </h2>
            <h3 className="mt-8 text-lg font-semibold leading-6">
                Fill level
            </h3>
            <h2 className="mt-2">{beans.fillLevel}g</h2>
            <h3 className="mt-8 text-lg font-semibold leading-6">
                Updated at
            </h3>
            <p className="mt-2">{beans.dateTime} Uhr</p>
            <h2 className="mt-8 text-lg font-semibold leading-6">
                Capacity
            </h2>
            <div className="mt-2 max-w-[300px]">
                <DoughnutChart fillLevel={beans.fillLevel} />
            </div>
            <h3 className="mt-10 text-md font-semibold leading-6">
                Coffe capacity is {((beans.fillLevel / 6000) * 100).toFixed(2)}%
            </h3>
        </div>
    );
}