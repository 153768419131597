import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ fillLevel }) => {
  const data = {
    labels: [
      'Fill Level',
      'Remaining Capacity',
    ],
    datasets: [
      {
        label: "Capacity",
        data: [fillLevel, 6000 - fillLevel > 0 ? 6000 - fillLevel : 0],
        backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut data={data} />;
};

export default DoughnutChart;