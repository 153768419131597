import { useEffect, useState } from "react";
import { fetchHistoricalData } from "../utils/api";
import { Line } from "react-chartjs-2";
import { CategoryScale, Chart, LinearScale, PointElement, LineElement } from "chart.js";
import Select from "../inputs/Select";
import moment from "moment";
import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/20/solid";

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);

class modes {
    static last90Days = "last-90-days";
    static last30Days = "last-30-days";
    static last7Days = "last-7-days";
    static last24Hours = "last-24-hours";
    static lastHour = "last-hour";
}

export default function HistoryCard() {
    // convert now to the format: "2024-03-15 00:00:00"
    const now = new Date();

    const [timeSeries, setTimeSeries] = useState([]);
    const [stepSize, setStepSize] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const [mode, setMode] = useState(modes.last30Days);
    
    const fetchData = async () => {
        const historyData = await fetchHistoricalData({
            startTime,
            endTime,
            stepSize,
        });
        
        historyData && setTimeSeries(historyData.time_series);
    }

    useEffect(() => {
        fetchData();
    }, [startTime, endTime, stepSize]);

    useEffect(() => {
        const now = new Date();
        switch (mode) {
            case modes.last90Days:
                setStepSize("day");
                setStartTime(moment(now.getTime() - 90 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD HH:mm:ss"));
                break;
            case modes.last30Days:
                setStepSize("day");
                setStartTime(moment(now.getTime() - 30 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD HH:mm:ss"));
                break;
            case modes.last7Days:
                setStepSize("day");
                setStartTime(moment(now.getTime() - 7 * 24 * 60 * 60 * 1000).format("YYYY-MM-DD HH:mm:ss"));
                break;
            case modes.last24Hours:
                setStepSize("hour");
                setStartTime(moment(now.getTime() - 24 * 60 * 60 * 1000).format("YYYY-MM-DD HH:mm:ss"));
                break;
            case modes.lastHour:
                setStepSize("5-minute");
                setStartTime(moment(now.getTime() - 60 * 60 * 1000).format("YYYY-MM-DD HH:mm:ss"));
                break;
            default:
                break;
        }

        setEndTime(moment(now).format("YYYY-MM-DD HH:mm:ss"));
    }, [mode]);

    return (
        <div className="rounded-2xl border-gray-900 border px-8 pb-8 lg:col-span-2">
            <h2 className="mt-10 text-2xl font-semibold leading-6">
            Fill level over time
            </h2>
            <div className="grid lg:grid-cols-3 gap-4 mt-6">
                <div className="lg:col-span-1 ">
                    <Select
                        label="Mode"
                        options={[
                            modes.last90Days,
                            modes.last30Days,
                            modes.last7Days,
                            modes.last24Hours,
                            modes.lastHour,
                        ]}
                        selected={mode}
                        onChange={(value) => {
                            setMode(value);
                        }}
                        optionToLabel={(option) => {
                            if (option === modes.last90Days) {
                                return "Last 90 days";
                            }
                            if (option === modes.last30Days) {
                                return "Last 30 days";
                            }
                            if (option === modes.last7Days) {
                                return "Last 7 days";
                            }
                            if (option === modes.last24Hours) {
                                return "Last 24 hours";
                            }
                            if (option === modes.lastHour) {
                                return "Last Hour";
                            }

                            return "Unsupported";
                        }}
                        optionToValue={(option) => option}
                    />
                </div>
            </div>

            <div className="mt-4">
                <Disclosure >
                {({ open }) => (
                    <>
                    <Disclosure.Button className="flex w-full items-start text-left text-gray-900">
                        <span className="text-sm font-semibold leading-7">Advanced</span>
                        <span className="ml-2 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">
                        <div className="grid lg:grid-cols-3 gap-4 mt-2">
                            <div className="">
                            <Select
                                label="Step size"
                                options={[
                                    "minute", "5-minute", "10-minute", "15-minute", "30-minute", "hour", "day"
                                ]}
                                selected={stepSize}
                                onChange={(value) => {
                                    setStepSize(value);
                                }}
                                optionToLabel={(option) => {
                                    if (option === "minute") {
                                        return "Minutes";
                                    }
                                    if (option === "5-minute") {
                                        return "5 Minutes";
                                    }
                                    if (option === "10-minute") {
                                        return "10 Minutes";
                                    }
                                    if (option === "15-minute") {
                                        return "15 Minutes";
                                    }
                                    if (option === "30-minute") {
                                        return "30 Minutes";
                                    }
                                    if (option === "hour") {
                                        return "Hours";
                                    }
                                    if (option === "day") {
                                        return "Days";
                                    }

                                    return "Unsupported";
                                }}
                                optionToValue={(option) => option}
                            />
                        </div>
                        <div className="">
                            <label htmlFor="startTime" className="block text-sm font-medium leading-6 text-gray-900">
                                Start time
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="startTime"
                                    id="startTime"
                                    value={startTime}
                                    onChange={(e) => setStartTime(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 pl-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="Start time"
                                />
                            </div>
                        </div>
                        <div className="">
                            <label htmlFor="endTime" className="block text-sm font-medium leading-6 text-gray-900">
                                End time
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="endTime"
                                    id="endTime"
                                    value={endTime}
                                    onChange={(e) => setEndTime(e.target.value)}
                                    className="block w-full rounded-md border-0 py-1.5 pl-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    placeholder="End time"
                                />
                            </div>
                        </div>
                        </div>
                    </Disclosure.Panel>
                    </>)}
                </Disclosure>
            </div>

            { timeSeries && (
                <div className="mt-10 min-h-96">
                    <Line id="test123" data={
                        {
                            labels: Object.entries(timeSeries).reverse().map((entry) => {
                                return new Date(entry[0]).toLocaleDateString("de", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                });
                            }),
                            datasets: [
                                {
                                    label: "Fill level",
                                    data: Object.entries(timeSeries).reverse().map((entry) => entry[1]),
                                    fill: false,
                                    cubicInterpolationMode: 'monotone',
                                    backgroundColor: "rgba(75, 192, 192, 0.2)",
                                    borderColor: "rgba(75, 192, 192, 1)",
                                },
                            ],
                        }
                    }
                        options={{
                            scales: {
                                x: {
                                   ticks: {
                                        maxTicksLimit: 10,
                                      minRotation: 80,
                                      maxRotation: 80,
                                   }
                                },
                                y: {
                                    beginAtZero: true,
                                   
                                }
                             },
                                responsive: true,
                                maintainAspectRatio: false
                        }}
                    />
                </div>
            )

            }
        </div>
    );
}