export async function fetchData() {
  try {
    const response = await fetch("https://bohnapi.digit-one.com/data/latest");

    const data = await response.json();

    return data.data;
  } catch (error) {
    console.error(error);
  } finally {
    console.log("all done");
  }
}


export async function fetchHistoricalData({ startTime, endTime, stepSize = '5-minute' }) {
  try {
    const response = await fetch("https://bohnapi.digit-one.com/data?" + new URLSearchParams({ 
      start_time: startTime, 
      end_time: endTime, 
      step_size: stepSize 
    }));

    const data = await response.json();

    return data.data;
  } catch (error) {
    console.error(error);
  } finally {
    console.log("all done");
  }
}
